import { Theme } from 'app'
import { InfoCircleIcon, CrosshairIcon } from 'assets/icons'
import { JourneyHistoryCard, Map, Logo, ThemeSwitch, InformationCard, RouteDetails } from 'components'
import { React, Touchable, View, Text, useTheme, onMount, useState, useRedux, Link } from 'lib'
import { Map as MapActions } from 'actions'

const RouteButtons: React.FC<{ setInfoCardVisible: (param: boolean) => void }> = ({ setInfoCardVisible }) => {
  const { map } = useRedux('Map')
  const { theme: currentTheme, isLight } = useTheme()
  const isSmall = Theme.hooks.down('small')

  const styles = getStyles(currentTheme)

  function crosshairPress() {
    MapActions.fitToUserLocation({ isSmall, map })
  }

  return (
    <View style={styles.bottomControlWrapper}>
      <Touchable variant={'row alignCenter justifyCenter'} style={styles.informationButton} onPress={crosshairPress}>
        <CrosshairIcon size={24} color={isLight ? Theme.colors.greyscale2 : Theme.colors.white}/>
      </Touchable>

      <Touchable variant={'row alignCenter justifyCenter'} style={styles.informationButton} onPress={() => setInfoCardVisible(true)}>
        <InfoCircleIcon size={24} color={isLight ? Theme.colors.greyscale2 : Theme.colors.white}/>
        {!isSmall && <Text variant={`p2 ${isLight ? 'greyscale2' : 'white'} marginLeft:1`} text={`Information`}/>}
      </Touchable>

      <ThemeSwitch/>
    </View>
  )
}

const RouteMap: React.FC = () => {
  const { theme: currentTheme, getTheme } = useTheme()
  const { route } = useRedux('LiveTracking')

  const [infoCardVisible, setInfoCardVisible] = useState(false)

  const styles = getStyles(currentTheme)

  onMount(() => getTheme())

  const isSmall = Theme.hooks.down('small')
  const showLogo = !Object.keys(route)?.length || !isSmall
  const showSearchBar = Object.keys(route)?.length && isSmall

  return (
    <View style={styles.pageWrapper}>
      {showLogo ? (
        <Link variant={'plain'} to={'/'}>
          <Logo style={styles.logo} showText={false}/>
        </Link>
      ) : null}
      {(showSearchBar && route?.destination) ? (
        <View style={styles.routeDetailsSection}><RouteDetails/></View>
      ) : null}

      <View style={styles.innerWrapper}>
        <View style={styles.routeDetailsWrapper}>
          {isSmall && <RouteButtons setInfoCardVisible={setInfoCardVisible}/>}
          <JourneyHistoryCard/>
        </View>

        {!isSmall && <RouteButtons setInfoCardVisible={setInfoCardVisible}/>}
      </View>

      <InformationCard visible={infoCardVisible} onDismiss={() => setInfoCardVisible(false)}/>

      <Map/>
    </View>
  )
}

export default RouteMap

const getStyles = (theme: 'light' | 'dark') => ({
  pageWrapper: {
    width: '100vw',
    height: '100svh',
    position: 'relative',
    overflow: 'hidden',
  },
  innerWrapper: {
    height: 'fit-content',
    pointerEvents: 'none',
  },
  routeDetailsWrapper: {
    position: 'fixed',
    zIndex: 99,
    marginTop: Theme.spacing(2),
    marginLeft: Theme.spacing(2),

    [Theme.media.up('small')]: {
      width: '50vw',
    },

    [Theme.media.down('small')]: {
      bottom: 0,
      left: 0,
      right: 0,
      margin: 0,
    },
  },
  logo: {
    height: 40,
    flexShrink: 0,
    position: 'fixed',
    zIndex: 99,
    top: 0,
    right: -96,
    marginRight: Theme.spacing(2),
    marginTop: Theme.spacing(2),
    cursor: 'pointer',
  },

  bottomControlWrapper: {
    position: 'fixed',
    zIndex: 99999,
    bottom: 0,
    right: 0,
    marginBottom: Theme.spacing(2),
    marginRight: Theme.spacing(2),
    pointerEvents: 'auto',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: Theme.spacing(2),

    [Theme.media.down('small')]: {
      position: 'static',
      width: 'fit-content',
      marginLeft: 'auto',
      flexDirection: 'column-reverse',
      zIndex: 99,
    },
  },
  informationButton: {
    maxHeight: 42,
    background: theme === 'light' ? Theme.colors.white + '80' : Theme.colors.white + '4d',
    boxShadow: '0px 4px 24px ' + Theme.colors.black + '40',
    backdropFilter: 'blur(5px)',
    borderRadius: Theme.values.borderRadiusMedium,
    padding: `${Theme.spacing(1)}px ${Theme.spacing(2)}px`,
    // marginRight: Theme.spacing(2),

    [Theme.media.down('small')]: {
      maxHeight: 'none',
      // marginRight: 0,
      padding: Theme.spacing(2),
      borderRadius: '50%',
    },
  },

  routeDetailsSection: {
    position: 'absolute',
    top: 0,
    zIndex: 99,
    right: 0,
    left: 0,

    marginTop: Theme.spacing(2),
    ...Theme.marginHorizontal(2),
    padding: Theme.spacing(2),

    backgroundColor: Theme.colors.white,
    borderRadius: Theme.values.borderRadiusMedium,
  },
})
